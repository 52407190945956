<template>
    <div class="page-index-box">
        <page-header type="fixed"></page-header>
        <div class="container page-index">
            <swiper :options="swiperOption" ref="mySwiper">
                <swiper-slide>
                  <swiper-item1 :pageIndex="pageIndex" :showIndex="showIndex"></swiper-item1>
                </swiper-slide>
                <swiper-slide>
                    <swiper-item2 :pageIndex="pageIndex" :showIndex="showIndex"></swiper-item2>
                </swiper-slide>
                <swiper-slide>
                    <swiper-item3 :pageIndex="pageIndex" :showIndex="showIndex"></swiper-item3>
                </swiper-slide>
                <swiper-slide>
                    <swiper-item4 :pageIndex="pageIndex" :showIndex="showIndex"></swiper-item4>
                </swiper-slide>
                <swiper-slide>
                    <swiper-item5 :pageIndex="pageIndex" :showIndex="showIndex"></swiper-item5>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
        </div>
        <tool-bar @backtotop="backtotop"></tool-bar>
    </div>
</template>

<script>
import Vue from 'vue';

import "swiper/css/swiper.css";

import PageHeader from "@/layout/components/pageHeader";

import SwiperItem1 from './components/swiper-item1';
import SwiperItem2 from './components/swiper-item2';
import SwiperItem3 from './components/swiper-item3';
import SwiperItem4 from './components/swiper-item4';
import SwiperItem5 from './components/swiper-item5';
import ToolBar from './components/tool-bar';

const VueAwesomeSwiper = require('vue-awesome-swiper');
Vue.use(VueAwesomeSwiper)


export default {
    components: { PageHeader,SwiperItem1,SwiperItem2,SwiperItem3,SwiperItem4,SwiperItem5,ToolBar },
    data() {
        return {
            swiperOption: null,
            pageIndex: 0,
            showIndex: 1,
            timer: null
        };
    },
    computed: {
      swiper() {
        return this.$refs.mySwiper.$swiper
      }
    },
    created() {
        const _this = this;
        this.swiperOption = {
            direction: "vertical",
            mousewheel: {
                forceToAxis: true,
                invert: true,
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
                type: 'bullets',
                renderBullet: function (index, className) {
                    let _index = index + 1;
                    _index = _index < 10 ? "0" + _index : _index;
                    return `<span class="${className}">${_index}</span>`;
                },
            },
            on: {
                init() {
                    _this.showPage(this.activeIndex);
                },
                slideChange() {
                    clearTimeout(_this.timer);
                },
                slideChangeTransitionEnd() {
                    _this.showPage(this.activeIndex);
                },
            },
        };
    },
    methods: {
        showPage(pageIndex) {
            const that = this;
            that.pageIndex = pageIndex + 1;
            that.showIndex = 0;
            //clearTimeout(that.timer);
            //that.dtime(0);
        },
        // dtime(time) {
        //     var that = this,
        //         _time = time;
        //     if (_time > 10) {
        //         return;
        //     } else {
        //         that.showIndex += 1;
        //         _time++;
        //         that.timer = setTimeout(function () {
        //             that.dtime(_time);
        //         }, 200);
        //     }
        // },
        backtotop(){
           this.swiper.slideTo(0,1000, false);
           this.showPage(0);
        }
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/animate.min.css';

.page-index {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #000000;
    .swiper-container {
        height: 100%;
        .swiper-box {
            width: 100%;
            height: 100%;
            overflow: hidden;
            ::v-deep .hide {
                visibility: hidden;
            }
            ::v-deep .show {
                visibility: visible;
            }
            
        }
        .swiper-pagination {
            right: 40px;
            width: auto;
            bottom: auto;
            left: auto;
            ::v-deep .swiper-pagination-bullet {
                width: auto;
                height: auto;
                background: transparent;
                font-size: 16px;
                font-weight: 600;
                color: #fff;
                opacity: 0.3;
                margin: 20px 0;
                &.swiper-pagination-bullet-active {
                    opacity: 1;
                }
            }
            @media screen and (max-width: $--screen-md) {
                right: 10px;
            }
        }
    }
}
</style>