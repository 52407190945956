<template>
    <div class="swiper-box swiper-box3">
        <div v-if="pageIndex == 3" class="page-box-layout">
            <div>
                <div class="title animated fadeclear fadeInUp1">
                    专业服务
                </div>
                <div class="tips animated fadeclear fadeInUp3">
                    提供全方位的服务解决方案
                </div>
                <el-row :gutter="20">
                    <el-col :xs="12" :sm="6">
                        <a :href="`${ssweb}/apppro`" target="_blank" class="item1 animated fadeclear fadeInUp2">
                            <div class="bg"></div>
                            <div class="logo animated"><img src="@/assets/img/index/APPicon.png"/></div>
                            <div class="info">
                                <div class="name">秀动专业版APP</div>
                                <div class="desc">集成验票、结算、报表等一站式服务的移动端工具</div>
                            </div>
                        </a>
                    </el-col>
                    <el-col :xs="12" :sm="6">
                        <a :href="`${ssweb}/apps`" target="_blank" class="item2 animated fadeclear fadeInUp3">
                            <div class="bg"></div>
                            <div class="logo animated"><img src="@/assets/img/index/yuemiappicon.png"/></div>
                            <div class="info">
                                <div class="name">秀动APP</div>
                                <div class="desc">为千万乐迷提供演出购票、音乐周边等现场娱乐综合服务</div>
                            </div>
                        </a>
                    </el-col>
                    <el-col :xs="12" :sm="6">
                        <a :href="`${ssweb}/musicplan`" target="_blank" class="item3 animated fadeclear fadeInUp4">
                            <div class="bg"></div>
                            <div class="logo animated"><img src="@/assets/img/index/showstartcx.png"/></div>
                            <div class="info">
                                <div class="name">音乐人巡演服务</div>
                                <div class="desc">为音乐人提供全方位的演出策划与执行服务</div>
                            </div>
                        </a>
                    </el-col>
                    <el-col :xs="12" :sm="6">
                        <a :href="`${ssweb}/showfun`" target="_blank" class="item4 animated fadeclear fadeInUp5">
                            <div class="bg"></div>
                            <div class="logo animated"><img src="@/assets/img/index/showfunicon.png"/></div>
                            <div class="info">
                                <div class="name">SHOWFUN</div>
                                <div class="desc">为乐迷提供正版音乐周边交易服务，入驻方式包括代理销售与授权生产</div>
                            </div>
                        </a>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        pageIndex: {
            type: Number,
            default: 0,
        },
        showIndex: {
            type: Number,
            default: 1,
        },
    },
    data(){
        return{
            ssweb:`${process.env.VUE_APP_SSWEB_URL}`
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/animate.scss';
.swiper-box3 {
    background: url('~@/assets/img/index/bg3.png') center center no-repeat;
    background-size: cover;
    .page-box-layout{
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        text-align: center;
        .title{
            font-size: 36px;
        }
        .tips{
            font-size: 16px;
            margin-top: 20px;
        }
        .el-row{
            margin-top: 30px;
            max-width: 1180px;
            a{
                display: block;
                height: 340px;
                text-align: center;
                position: relative;
                padding-top: 80px; ;
                margin-top: 20px;
                .logo{
                    position: relative;
                    visibility: hidden;

                }
                .info{
                    position: relative;
                    margin-top: 80px;
                    .name{
                        font-size: 20px;  
                        color: #fff;
                    }
                    .desc{
                        color: $--color-text-regular;
                        margin-top: 15px;
                        line-height: 24px;
                        padding: 0 25px;
                    }
                }
                .bg{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    display: none;
                    background: rgb(0 18 215 / 75%);
                }
                &.item1{
                    background: url('~@/assets/img/index/p31.png') center center no-repeat;
                }
                &.item2{
                    background: url('~@/assets/img/index/p32.png') center center no-repeat;
                }
                &.item3{
                    background: url('~@/assets/img/index/p33.png') center center no-repeat;
                }
                &.item4{
                    background: url('~@/assets/img/index/p34.png') center center no-repeat;
                }
                &:hover{
                    .bg{
                        display: block;
                    }
                    .desc{
                        color: #fff;
                    }
                    .logo{
                        visibility: visible;
                        animation-name: slideInUp;
                    }
                }


                @media screen and (max-width: $--screen-md) {
                    height: 200px;
                    padding-top: 30px;
                    .info{
                        margin-top: 10px;
                        .name{
                            font-size: 14px;  
                        }
                        .desc{
                            margin-top: 5px;
                            padding: 0 10px;
                        }
                    }
                }
            }
        }
    }
}
</style>