<template>
    <div class="swiper-box swiper-box5">
        <div v-if="pageIndex == 5" class="page-box-layout">
            <div class="title animated fadeclear fadeInUp" >合作伙伴</div>
            <div class="tab animated fadeclear fadeInUp5">
                <span class="btn" :class="tabIndex == 1 ? 'active' : ''" @click="tab(1)">合作客户</span>
                <span class="btn" :class="tabIndex == 2 ? 'active' : ''" @click="tab(2)">合作平台</span>
            </div>
            <div class="tab-content">
                <div v-show="tabIndex == 1" class="tab1 clearfix">
                    <a :href="`${ssweb}/artist/59288`" target="_blank" class="animated fadeclear fadeInDown4" >
                        <img src="@/assets/img/index/1.png"/>
                        <div class="info animated">草东没有派对</div>
                    </a>
                    <a :href="`${ssweb}/artist/635918`" target="_blank" class="animated fadeclear fadeInDown3">
                        <img src="@/assets/img/index/2.png"/>
                        <div class="info animated">泥鳅Zinco</div>
                    </a>
                    <a :href="`${ssweb}/host/118339`" target="_blank" class="animated fadeclear fadeInDown2">
                        <img src="@/assets/img/index/3.png"/>
                        <div class="info animated">赤瞳音乐</div>
                    </a>
                    <a :href="`${ssweb}/artist/55722`" target="_blank" class="animated fadeclear fadeInDown3">
                        <img src="@/assets/img/index/4.png"/>
                        <div class="info animated">HIGHER BROTHERS</div>
                    </a>
                    <a :href="`${ssweb}/host/4603`" target="_blank" class="animated fadeclear fadeInDown4">
                        <img src="@/assets/img/index/5.png"/>
                        <div class="info animated">摩登天空</div>
                    </a>


                    <a :href="`${ssweb}/venue/814213`" target="_blank" class="animated fadeclear fadeInLeft3">
                        <img src="@/assets/img/index/6.png"/>
                        <div class="info animated">MAO Livehouse广州</div>
                    </a>
                    <a :href="`${ssweb}/host/692773`" target="_blank" class="animated zoomIn1">
                        <img src="@/assets/img/index/7.png"/>
                        <div class="info animated">晓峰音乐公社</div>
                    </a>
                    <a :href="`${ssweb}/artist/2667`" target="_blank" class="animated zoomIn">
                        <img src="@/assets/img/index/8.png"/>
                        <div class="info animated">痛仰</div>
                    </a>
                    <a :href="`${ssweb}/host/442310`" target="_blank" class="animated zoomIn1">
                        <img src="@/assets/img/index/9.png"/>
                        <div class="info animated">街声StreetVoice</div>
                    </a>
                    <a :href="`${ssweb}/artist/2678`" target="_blank" class="animated fadeclear fadeInRight3" >
                        <img src="@/assets/img/index/10.png"/>
                        <div class="info animated">布衣乐队</div>
                    </a>


                    <a :href="`${ssweb}/host/1605377`" target="_blank" class="animated fadeclear fadeInUp4">
                        <img src="@/assets/img/index/11.png"/>
                        <div class="info animated">刺猬兄弟</div>
                    </a>
                    <a :href="`${ssweb}/host/5263`" target="_blank" class="animated fadeclear fadeInUp3" >
                        <img src="@/assets/img/index/12.png"/>
                        <div class="info animated">北漂团伙</div>
                    </a>
                    <a :href="`${ssweb}/venue/113676`" target="_blank" class="animated fadeclear fadeInUp2" >
                        <img src="@/assets/img/index/13.png"/>
                        <div class="info animated">疆进酒·OMNI SPACE</div>
                    </a>
                    <a :href="`${ssweb}/host/518555`" target="_blank" class="animated fadeclear fadeInUp3">
                        <img src="@/assets/img/index/14.png"/>
                        <div class="info animated">GOSH Music</div>
                    </a>
                    <a :href="`${ssweb}/artist/86643`" target="_blank" class="animated fadeclear fadeInUp4">
                        <img src="@/assets/img/index/15.png"/>
                        <div class="info animated">房东的猫</div>
                    </a>
                </div>
                <div v-show="tabIndex == 2" class="tab2">
                    <a href="https://www.douyin.com/" target="_blank" class="animated fadeclear bounceIn">
                        <img src="@/assets/img/index/douyin.png" class="animated"><span>抖音</span>
                    </a>
                    <a href="https://y.qq.com/" target="_blank" class="animated fadeclear bounceIn1">
                        <img src="@/assets/img/index/QQyinle.png" class="animated"><span>QQ音乐</span>
                    </a>
                    <a href="https://music.163.com/" target="_blank" class="animated fadeclear bounceIn2">
                        <img src="@/assets/img/index/wangyiyunyinle.png" class="animated"><span>网易云音乐</span>
                    </a>
                    <a href="http://www.wenkor.com/" target="_blank" class="animated fadeclear bounceIn3">
                        <img src="@/assets/img/index/wenke.png" class="animated"><span>文客</span>
                    </a>
                    <a href="http://www.gewara.com/" target="_blank" class="animated fadeclear bounceIn4">
                        <img src="@/assets/img/index/maoyan.png" class="animated"><span>猫眼</span>
                    </a>
                    <a href="https://www.toutiao.com/" target="_blank" class="animated fadeclear bounceIn1">
                        <img src="@/assets/img/index/jinritoutiao.png" class="animated"><span>今日头条</span>
                    </a>
                </div>
                <page-footer class="page-index-footer"></page-footer>
            </div>
        </div>   
    </div>
</template>

<script>
import PageFooter from '@/layout/components/pageFooter';
export default {
    props: {
        pageIndex: {
            type: Number,
            default: 0,
        },
        showIndex: {
            type: Number,
            default: 1,
        }
    },
    components:{PageFooter},
    data() {
        return {
            tabIndex:1,
            ssweb:`${process.env.VUE_APP_SSWEB_URL}`
        };
    },
    methods:{
        tab(index){
            this.tabIndex = index;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/animate.scss';
.swiper-box5 {
    background: url('~@/assets/img/index/p5bg.png') #161616 center center no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    min-width: $--page-width;
    .title{
        font-size: 36px;
    } 
   .tab{
       margin-top: 50px;
       .btn{
           display: inline-block;
            margin: 0 15px;
            width: 120px;
            height: 40px;
            line-height: 40px;
            background: rgba(0,0,0,1);
            border-radius: 20px;
            color: $--color-text-regular;
            cursor: pointer;
            text-align: center;
            &.active{
                background: $--color-theme;
                color: #ffffff;
            }
       }
   }
   .tab-content{
       margin-top: 65px;
       height: 396px;
       .tab1{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            a{
                // float: left;
                width: 244px;
                height: 132px;
                margin: 0 2px;
                position: relative;
                img{
                    width: 100%;
                    height: 100%;
                }
                .info{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    align-items: center;
                    justify-content: center;
                    background: rgba(0,0,0,0.8);
                    color: #ffffff;
                    font-size: 20px;
                    font-weight: 500;
                    opacity: 0.8;
                    display: none;
                }
                &:hover{
                    .info{
                        display: flex;
                        animation-name: fadeIn;
                    }
                }
            }
       }
       .tab2{
           a{
                display: inline-block;
                width: 193px;
                height: 300px;
                background: linear-gradient(180deg,rgba(38,38,38,1) 0,rgba(22,22,22,.67) 100%);
                border-radius: 8px;
                padding-top: 60px;
                margin: 0 3px;
               img{
                   animation-name: scaleRotateOut;
               }
               span{
                    display: block;
                    margin-top: 45px;
                    font-size: 24px;
                    font-weight: 400;
                    color: #ffffff;
               }
               &:hover{
                    background: linear-gradient(180deg,rgba(38,38,38,1) 0,rgba(51,51,51,1) 100%);
                    .animated{
                        animation-name: scaleRotateIn;
                    }
               }
           }
       }
   }


    @media screen and (max-width: $--screen-md) {
        min-width: auto;
        .title{
            font-size: 18px;
        } 
        .tab{
            margin-top: 20px;
        }
        .tab-content{
            margin-top: 20px;
            height: auto;
            .tab1 {
                a{
                    width: 90px;
                    height: 50px;
                }
            }
            .tab2{
                a{
                    width: 98px;
                    height: 150px;
                    padding-top: 30px;
                    img{
                        width: 50px;
                    }
                    span{
                        margin-top: 15px;
                        font-size: 13px;
                    }
                }
            }
        }
    }

}


.page-footer{
    // margin-left: -100%;
    // margin-right: -100%;
    &.page-index-footer{
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        background: rgba(22,22,24,0.9);
        ::v-deep .page-footer-content{
            display: none;
        }
    }
}

// @media screen and (min-width: $--screen-xxl) {
//     .page-footer{
//         &.page-index-footer{
//             position: static;
//             margin-left: -100%;
//             margin-right: -100%;
//             width: auto;
//             ::v-deep .page-footer-content{
//                 display: block;
//             }
//         }
//     }
// }

</style>