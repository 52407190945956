<template>
    <div class="swiper-box swiper-box4">
        <div v-if="pageIndex == 4">
            <swiper v-if="swiperOption" :options="swiperOption">
                <swiper-slide class="swiper-slide-1">
                    <div class="bg"></div>
                    <div class="page-box-layout">
                        <div v-if="index == 0">
                            <div class="title animated fadeclear fadeInUp1">行业案例</div>
                            <div class="tips animated fadeclear fadeInUp3">结合秀动平台资源为从业者提供更大的舞台</div>
                            <div class="info animated fadeclear fadeInUp5">
                                <div class="name">麦田音乐节</div>
                                <div class="desc">
                                    “身在都市，心有麦田”，这是一个以音乐为底色，向青春、情怀致敬的盛大派对，演出阵容汇聚海内外众多知名音乐人及乐队。
                                </div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide class="swiper-slide-2">
                    <div class="bg"></div>
                    <div class="page-box-layout">
                        <div v-if="index == 1">
                            <div class="title animated fadeclear fadeInUp1">行业案例</div>
                            <div class="tips animated fadeclear fadeInUp3">结合秀动平台资源为从业者提供更大的舞台</div>
                            <div class="info animated fadeclear fadeInUp5">
                                <div class="name">LPA独立音乐盛典</div>
                                <div
                                    class="desc"
                                >用现场演出激动人心的声量，唤起原创音乐潜藏的无限力量，誓将“探索独立音乐的更多可能性，为音乐人和乐迷更好地服务”进行到底。</div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide class="swiper-slide-3">
                    <div class="bg"></div>
                    <div class="page-box-layout">
                        <div v-if="index == 2">
                            <div class="title animated fadeclear fadeInUp1">行业案例</div>
                            <div class="tips animated fadeclear fadeInUp3">结合秀动平台资源为从业者提供更大的舞台</div>
                            <div class="info animated fadeclear fadeInUp5">
                                <div class="name">Move!!Tube系列直播</div>
                                <div
                                    class="desc"
                                >开拓线上LIVE秀全新领域，聚集Live演出、音乐人故事、微综艺、粉丝互动等娱乐精品内容，让乐迷感受到更加多元化与立体化的独立音乐人</div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide class="swiper-slide-4">
                    <div class="bg"></div>
                    <div class="page-box-layout">
                        <div v-if="index == 3">
                            <div class="title animated fadeclear fadeInUp1">行业案例</div>
                            <div class="tips animated fadeclear fadeInUp3">结合秀动平台资源为从业者提供更大的舞台</div>
                            <div class="info animated fadeclear fadeInUp5">
                                <div class="name">秀动×乐夏 巡星计划</div>
                                <div
                                    class="desc"
                                >联合《乐队的夏天》与热爱音乐的800组乐队一起探索音乐的更多可能，通过召集、选拔、投票等活动环节为乐迷呈献更多宝藏乐队。</div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide class="swiper-slide-5">
                    <div class="bg"></div>
                    <div class="page-box-layout">
                        <div v-if="index == 4">
                            <div class="title animated fadeclear fadeInUp1">行业案例</div>
                            <div class="tips animated fadeclear fadeInUp3">结合秀动平台资源为从业者提供更大的舞台</div>
                            <div class="info animated fadeclear fadeInUp5">
                                <div class="name">秀动音乐众筹</div>
                                <div class="desc">秀动众筹搭建了音乐人与乐迷紧密联结的桥梁，独家预售徐梦圆同名专辑，数十小时内众筹金额便突破50000元。</div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
            <div class="swiper-diy clearfix">
                <div class="swiper-pagination1 fl" slot="pagination"></div>
                <div class="swiper-button-prev fl">&lt;</div>
                <div class="swiper-button-next fl">&gt;</div>
            </div>
        </div>
    </div>
</template>

<script>
// import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
    // components:{ swiper, swiperSlide},
    props: {
        pageIndex: {
            type: Number,
            default: 0,
        },
        showIndex: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            index: 0,
            swiperOption: null,
        };
    },
    mounted(){
        const _this = this;
        this.swiperOption = {
            pagination: {
                el: ".swiper-pagination1",
                type: "fraction",
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            on: {
                init() {
                    _this.index = this.activeIndex;
                },
                slideChangeTransitionEnd() {
                    _this.index = this.activeIndex;
                }
            },
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/animate.scss';
.swiper-box4 {
    > div{
        position: relative;
        height: 100%;
        .swiper-container{
            height: 100%;
            .swiper-slide{
                &.swiper-slide-1{
                    background: url('~@/assets/img/index/p41.png') center center no-repeat;
                    background-size: cover;
                }
                &.swiper-slide-2{
                    background: url('~@/assets/img/index/p42.png') center center no-repeat;
                    background-size: cover;
                }
                &.swiper-slide-3{
                    background: url('~@/assets/img/index/p43.png') center center no-repeat;
                    background-size: cover;
                }
                &.swiper-slide-4{
                    background: url('~@/assets/img/index/p44.png') center center no-repeat;
                    background-size: cover;
                }
                &.swiper-slide-5{
                    background: url('~@/assets/img/index/p45.png') center center no-repeat;
                    background-size: cover;
                }
                .page-box-layout{
                    position: relative;
                    display: flex;
                    align-items: center;
                    height: 100%;
                    color: #ffffff;
                    >div{
                        margin-top: -100px;
                    }
                    .title{
                        font-size: 36px;
                        font-weight: 700;
                    }
                    .tips{
                        font-size: 16px;
                        margin-top: 20px;
                    }
                    .info{
                        margin-top: 100px;
                        .name{
                            font-size: 64px;
                            font-weight: 700;
                        }
                        .desc{
                            font-size: 16px;
                            margin-top: 35px;
                            line-height: 24px;
                            max-width: 580px;
                        }
                    }
                    @media screen and (max-width: $--screen-md) {
                        padding: 0 30px;
                        .title{
                            font-size: 18px;
                        }
                        .tips{
                            font-size: 12px;
                        }
                        .info{
                            margin-top: 50px;
                            .name{
                                font-size: 32px;
                            }
                            .desc{
                                font-size: 12px;
                                margin-top: 20px;
                            }
                        }
                    }
                }
            }
            .bg{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(270deg,rgba(12,12,12,.18) 0,rgba(0,0,0,.85) 100%);
            }
        }
        .swiper-diy{
            position: absolute;
            width: $--page-width;
            max-width: 100%;
            left: 50%;
            // margin-left: (-$--page-width/2);
            bottom: 100px;
            z-index: 10;
            transform: translate(-50%) ;
            .swiper-pagination1,.swiper-button-prev, .swiper-button-next{
                position: static;
            }
            .swiper-pagination1{
                width: auto;
                color: #fff;
                font-size: 24px;
                margin-right: 30px;
                line-height: 33px;
                .swiper-pagination-current{
                    padding-right: 10px;
                }
                .swiper-pagination-total{
                    padding-left: 10px;
                }
            }
            .swiper-button-prev, .swiper-button-next{
                width: 33px;
                height: 33px;
                text-align: center;
                line-height: 33px;
                background: rgba(0,78,255,1);
                border-radius: 100%;
                font-size: 14px;
                color: #ffffff;
                margin-top: 0;
                &:after{
                    content: "";
                    display: none;
                }
            }
            .swiper-button-prev{
                margin-right: 30px;
            }
        }
    }
}
</style>