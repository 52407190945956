<template>
    <div class="index-tool-bar">
        <div class="erm-box">
            <div class="erm iconfont">&#xe628;</div>
            <div class="erm-info animated">
                <div class="erm-item">
                    <img src="@/assets/img/footer/wxcode.png" />
                    <div>
                        <img src="@/assets/img/footer/footer-weixin.png" />
                        <p>关注微信客服</p>
                        <p>在线咨询</p>
                    </div>
                </div>
                <div class="erm-item showcode">
                    <img src="@/assets/img/footer/showcode.png" />
                    <div>
                        <img src="@/assets/img/footer/footer-show.png" />
                        <p>扫码下载</p>
                        <p>秀动专业版APP</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="line"></div>
        <div class="back-to-top iconfont" @click="backtotop">&#xe627;</div>
    </div>
</template>

<script>
export default {
    methods:{
        backtotop(){
            this.$emit('backtotop');
        }
    }
}
</script>

<style lang="scss" scoped>
.index-tool-bar{
    position: fixed;
    bottom: 45px;
    right: 30px;
    z-index: 100;
    background: rgba(250,250,250,0.2);
    padding: 10px 14px;
    border-radius: 6px;
    .erm-box{
        .erm{
            font-size: 18px;
            color: rgba(255,255,255,0.5);
            &:hover{
                color: rgba(255,255,255,1);
            }
        }
        .erm-info{
            position: absolute;
            padding: 15px;
            background: #ffffff;
            border-radius: 4px;
            right: 46px;
            bottom: 0;
            display: none;
            .erm-item{
                white-space: nowrap;
                >img,>div{
                    display: inline-block;
                    vertical-align: middle;
                }
                >div{
                    text-align: center;
                    margin-left: 10px;
                    p{
                        margin-top: 5px;
                        font-size: 12px;
                        color: $--color-text-regular;
                    }
                }
                &.showcode{
                    border-top: 1px solid #EBEBEB;
                    padding-top: 15px;
                    margin-top: 15px;
                }
            }
        }
        &:hover{
            .erm-info{
                display: block;
                animation-name: fadeIn;
            }
        }
    }
    .line{
        width: 100%;
        margin: 10px 0;
        height: 1px;
        background: rgba(250,250,250,0.5);
    }
    .back-to-top{
        cursor: pointer;
        color: rgba(255,255,255,0.5);
        &:hover{
            color: rgba(255,255,255,1);
        }
    }
}
</style>