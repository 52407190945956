<template>
    <div class="swiper-box swiper-box1">
        <!-- <video muted="muted" src="~/assets/video/videofb.mp4" autoplay="autoplay" loop="loop"></video> -->
        <!-- <div class="bg"></div> -->
        <template v-if="pageIndex == 1">
            <div class="info">
                <div class="title animated fadeclear fadeInUp1">
                    <img src="@/assets/img/index/title1.png"/>
                </div>
                <div class="tips animated fadeclear fadeInUp3">
                    秀动经营平台为音乐人、场地、厂牌提供演出发布、销售、结算服务
                </div>
            </div>
            <div class="btn animated fadeclear fadeInUp5">
                <!-- <el-button type="primary" round @click="$router.push({path:'/register'})">入驻平台</el-button> -->
                <el-row>
                    <el-col :xs="12" :sm="6">
                        <router-link to="/register/claim?userType=3">
                            <div class="img"><img src="@/assets/img/common/icon_venue.png"/></div>
                            <div class="name">场地入驻 <span class="iconfont">&#xe626;</span></div>
                            <div class="desc">发布演出，众筹，管理小站</div>
                        </router-link>
                    </el-col>
                    <el-col :xs="12" :sm="6">
                        <router-link to="/register/claim?userType=2">
                            <div class="img"><img src="@/assets/img/common/icon_artist.png"/></div>
                            <div class="name">音乐人入驻 <span class="iconfont">&#xe626;</span></div>
                            <div class="desc">发布演出，上传歌曲，更新动态</div>
                        </router-link>
                    </el-col>
                    <el-col :xs="12" :sm="6">
                        <router-link to="/register/claim?userType=5">
                            <div class="img"><img src="@/assets/img/common/icon_host.png"/></div>
                            <div class="name">厂牌入驻 <span class="iconfont">&#xe626;</span></div>
                            <div class="desc">发布演出，众筹，管理小站</div>
                        </router-link>
                    </el-col>
                    <el-col :xs="12" :sm="6" class="release-showstart">
                        <div class="a" @click="$release()">
                            <div class="img img1"><img src="@/assets/img/common/icon_release.png"/></div>
                            <div class="img img2"><img src="@/assets/img/common/icon_release.gif"/></div>
                            <div class="name">发布音乐 <span class="iconfont">&#xe626;</span></div>
                            <div class="desc">一键上传200+音乐平台，100%收益</div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        pageIndex: {
            type: Number,
            default: 0,
        },
        showIndex: {
            type: Number,
            default: 1,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/animate.scss';
.swiper-box1 {
    // video {
    //     min-height: 100%;
    //     width: 100%;
    //     -o-object-fit: cover;
    //     object-fit: cover;
    // }
    background: url('~@/assets/img/index/bg1.png') center center no-repeat;
    background-size: cover;
    position: relative;
    // .bg {
    //     position: absolute;
    //     width: 100%;
    //     height: 100%;
    //     top: 0;
    //     left: 0;
    //     background: rgb(0 18 215 / 80%);
    // }
    .info {
        position: absolute;
        max-width: $--page-width;
        width: 90%;
        left: 50%;
        // margin-left: (-$--page-width/2);
        top: 50%;
        // margin-top: -115px;
        color: #fff;
        text-align: center;
        transform: translate(-50%,-115px) ;
        .title {
            img{
                max-width: 918px;
                width: 100%;
            }
        }
        .tips {
            font-size: 24px;
            margin-top: 30px;
            color: rgba(255,255,255,0.69);
            @media screen and (max-width: $--screen-md) {
                font-size: 13px;
            }
        }
    }
    .btn {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #1A1A1A;
        a,.a{
            display: block;
            color: #fff;
            text-align: center;
            padding: 55px 0;
            cursor: pointer;
            .img{
                height: 60px;
            }
            img{
                width: 50px;
            }
            .name{
                font-size: 32px;
                margin-top: 30px;
                .iconfont{
                    vertical-align: middle;
                    font-size: 14px;
                }
            }
            .desc{
                font-size: 20px;
                color: rgba(255,255,255,0.69);
                margin-top: 18px;
            }
            &:hover{
                background: $--color-theme;
            }

            @media screen and (max-width: $--screen-md) {
                padding: 20px 0;
                .name{
                    font-size: 14px;
                    margin-top: 10px;
                }
                .desc{
                    font-size: 12px;
                    margin-top: 10px;
                }
            }
        }
        .release-showstart{
            > div{
                img{
                    height: 60px;
                    width: auto;
                }
                .img2{
                    display: none;
                }
                &:hover{
                    background: #5914EE;
                    .img1{
                        display: none;
                    }
                    .img2{
                        display: block;
                    }
                }
            }
        }
    }
}
</style>