<template>
    <div class="swiper-box swiper-box2">
        <div v-if="pageIndex == 2" class="page-box-layout">
            <div class="left">
                <div class="title animated fadeclear fadeInUp1">
                    企业级票务平台
                </div>
                <div class="tips animated fadeclear fadeInUp3 ">
                    为音乐人、乐队、主办、剧院、场地、厂牌等提供优质演出经营服务
                </div>
                <div class="clerfix animated fadeclear fadeInUp5">
                    <dl class="fl">
                        <dt>专业票务服务</dt>
                        <dd>支持快速创建演出与库存管理、完成销售统计与现场验票</dd>
                    </dl>
                    <dl class="fl">
                        <dt>强化品牌运营</dt>
                        <dd>快速触达千万乐迷，支持小站定制、粉丝经营、数据统计</dd>
                    </dl>
                    <dl class="fl">
                        <dt>人工实时协助</dt>
                        <dd>策划、执行过程中遇到困难可联系平台获得业务经理的协助</dd>
                    </dl>
                    <dl class="fl">
                        <dt>结款迅速到账</dt>
                        <dd>演出票房、周边货款、众筹资金申请结款即可快速到账</dd>
                    </dl>
                </div>
            </div>
            <div class="right animated fadeclear bounceIn3 hidden-xs-only">
                <video
                    muted="muted"
                    src="@/assets/video/page2viedo.mp4"
                    autoplay="autoplay"
                    loop="loop"
                ></video>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        pageIndex: {
            type: Number,
            default: 0,
        },
        showIndex: {
            type: Number,
            default: 1,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/animate.scss';
.swiper-box2 {
    background: #000;
    .page-box-layout{
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: space-between;
    }
    .left{
        width: 800px;
        .title{
            font-size: 36px;
            color: #ffffff;
        }
        .tips{
            font-size: 16px;
            color: $--color-text-regular;
            margin: 18px 0;
        }
        dl{
            margin-top: 60px;
            width: 280px;
            margin-right: 100px;
            dt{
                font-size: 18px;
                color: #ffffff;
            }
            dd{
                color:$--color-text-regular;
                margin-top: 12px;
                line-height: 22px;
            }
        }
        @media screen and (max-width: $--screen-md) {
            padding: 0 30px;
            width: auto;
            .title{
                font-size: 16px;
            }
            .tips{
                font-size: 12px;
            }
            dl{
                margin-top: 20px;
                width: auto;
                margin-right: 0;
                dt{
                    font-size: 14px;
                }
            }
        }
    }
}
</style>