<template>
    <div class="page-footer">
        <div class="page-footer-content">
            <div class="page-box-layout clearfix">
                <div class="fl">
                    <dl>
                        <dt>平台产品</dt>
                        <dd>
                            <router-link to="/">经营平台</router-link>
                        </dd>
                        <dd>
                            <a :href="`${ssweb}/apps`" target="_blank">秀动APP</a>
                        </dd>
                        <dd>
                            <a :href="`${ssweb}/apppro`" target="_blank">秀动专业版</a>
                        </dd>
                        <dd>
                            <a :href="`${ssweb}/showfun`" target="_blank">SHOWFUN</a>
                        </dd>
                    </dl>
                    <dl>
                        <dt>关于秀动</dt>
                        <dd>
                            <a
                                :href="`${ssweb}/helpCenter#tab-about`"
                                target="_blank"
                            >平台介绍</a>
                        </dd>
                        <dd>
                            <a
                                :href="`${ssweb}/helpCenter#tab-aptitude`"
                                target="_blank"
                            >资质许可</a>
                        </dd>
                        <dd>
                            <a
                                :href="`${ssweb}/helpCenter#tab-falv`"
                                target="_blank"
                            >服务条款</a>
                        </dd>
                        <dd>
                            <a
                                :href="`${ssweb}/helpCenter#tab-privacy`"
                                target="_blank"
                            >隐私政策</a>
                        </dd>
                    </dl>
                    <dl>
                        <dt>友情链接</dt>
                        <dd>
                            <a href="https://y.qq.com/portal/piao_wu.html" target="_blank">QQ演出</a>
                        </dd>
                        <dd>
                            <a
                                href="https://music.163.com/show/m/portal?source=concert_detail"
                                target="_blank"
                            >云村有票</a>
                        </dd>
                        <dd>
                            <a href="http://www.gewara.com/" target="_blank">猫眼演出</a>
                        </dd>
                        <dd>
                            <a href="http://www.wenkor.com/" target="_blank">文客</a>
                        </dd>
                    </dl>
                </div>
                <div class="fr">
                    <div class="tel">
                        <div class="tel-num">400-188-6980</div>
                        <div class="tel-tips">客服热线：周一至周五 10:00~20:30</div>
                    </div>
                    <div class="code-bar clearfix">
                        <div class="code-item fl">
                            <img src="@/assets/img/footer/footer-weixin.png" class="img" />
                            <span>秀动微信客服</span>
                            <div class="code-pop">
                                <img src="@/assets/img/footer/wxcode.png" />
                            </div>
                        </div>
                        <div class="code-item fl">
                            <img src="@/assets/img/footer/footer-show.png" class="img" />
                            <span>秀动专业版APP</span>
                            <div class="code-pop">
                                <img src="@/assets/img/footer/showcode.png" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-footer-copyright">
            <div class="page-box-layout">
                <div class="clearfix">
                    <router-link to="/" class="logo fl">
                        <img src="@/assets/img/logo.png" />
                    </router-link>
                    <div class="third-link fr">
                        <a href="https://weibo.com/thshowstart" target="_blank">
                            <span class="iconfont">&#xe629;</span>
                        </a>
                        <a href="https://twitter.com/startoffical/" target="_blank">
                            <span class="iconfont">&#xe62b;</span>
                        </a>
                        <a href="https://www.facebook.com/showstartbooking/" target="_blank">
                            <span class="iconfont">&#xe62a;</span>
                        </a>
                        <a href="https://www.instagram.com/showstartofficial/" target="_blank">
                            <span class="iconfont">&#xe62c;</span>
                        </a>
                    </div>
                </div>
                <p>
                    <a href="http://about.taihe.com" target="_blank">太合音乐集团</a>
                    ©{{new Date().getFullYear()}}成都太合乐动科技有限公司 版权所有 <a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备14016442号-4</a>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            ssweb:`${process.env.VUE_APP_SSWEB_URL}`
        }
    }
}
</script>

<style lang="scss" scoped>
.page-footer {
    background: #161616;
    .page-footer-content {
        padding: 90px 0;
        .page-box-layout {
            > .fl {
                dl {
                    float: left;
                    width: 220px;
                    dt {
                        font-size: 16px;
                        color: #ffffff;
                        margin-bottom: 30px;
                    }
                    dd {
                        margin-top: 22px;
                        a {
                            color: $--color-text-regular;
                            &:hover {
                                color: $--color-theme;
                            }
                        }
                    }
                }
            }
            > .fr {
                .tel {
                    text-align: right;
                    .tel-num {
                        font-size: 36px;
                        color: #ffffff;
                    }
                    .tel-tips {
                        font-size: 16px;
                        color: $--color-text-regular;
                        margin-top: 10px;
                    }
                }
                .code-bar {
                    margin-top: 65px;
                    .code-item {
                        margin-left: 15px;
                        background: linear-gradient(
                            180deg,
                            rgba(38, 38, 38, 1) 0,
                            rgba(51, 51, 51, 1) 100%
                        );
                        border-radius: 4px;
                        font-size: 12px;
                        padding: 11.5px 24px;
                        color: #ffffff;
                        cursor: pointer;
                        position: relative;
                        .img {
                            margin-right: 15px;
                            width: 24px;
                        }
                        span {
                            display: inline-block;
                            vertical-align: middle;
                            height: 19px;
                            line-height: 19px;
                            border-left: 1px rgba(255, 255, 255, 0.21) solid;
                            padding-left: 15px;
                        }
                        .code-pop {
                            position: absolute;
                            left: 0;
                            bottom: 52px;
                            padding: 15px;
                            background: #ffffff;
                            display: none;
                            &::after {
                                content: "";
                                position: absolute;
                                width: 0;
                                height: 0;
                                left: 20px;
                                bottom: -5px;
                                border-left: 5px solid transparent;
                                border-right: 5px solid transparent;
                                border-top: 6px solid #fff;
                            }
                            img {
                                width: 105px;
                            }
                        }
                        &:hover{
                            .code-pop{
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
    .page-footer-copyright {
        border-top: 1px #333333 solid;
        padding: 30px 0;
        .third-link {
            a {
                margin-left: 20px;
                color: #fff;
                opacity: 0.5;
                &:hover{
                    opacity: 1;
                }
                span{
                    font-size: 24px;
                }
            }
        }
        p {
            color: $--color-text-regular;
            margin-top: 20px;
            a {
                color: #999999;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
</style>